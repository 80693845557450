<template>
    <div class="sign_container">
        <!--header-->
        <div class="sign_header">
            <div class="sign_header_img">
                <img :src='require("@/assets/images/icon/edit.png")'/>
            </div>
            <p class="sign_header_text">请填写基本信息</p>
        </div>
        <!--body-->
        <div class="sign_body">
            <!--信息填写-->
            <div class="sign_information_input">
                        <div class="sign_information_input_key">姓名:</div>
                        <div class="sign_information_input_value global_text_input">
                            <el-input v-model="data.name" placeholder="请输入姓名" />
                        </div>
                    </div>
            <div class="sign_information_input">
                        <div class="sign_information_input_key">省份:</div>
                        <div class="sign_information_input_value global_text_input">
                            <el-select v-model="data.province" @change='changeProvince' placeholder="请选择">
                                <el-option
                                v-for="(val,index) in config.provinces"
                                :key="index"
                                :label="val"
                                :value="val">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="sign_information_input">
                        <div class="sign_information_input_key">城市:</div>
                        <div class="sign_information_input_value global_text_input">
                            <el-select v-model="data.city" placeholder="请选择">
                                <el-option
                                v-for="(val,index) in config.citys"
                                :key="index"
                                :label="val"
                                :value="val">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
            <!--按钮-->
            <div class="sign_button">
                <el-button type="primary" size="mini">
                    <div class="button_tip_block" @click="doCommit">
                        <div class="button_img">
                            <img :src='require("@/assets/images/icon/apply.png")' />
                        </div>
                        <div class="button_text">进入会议</div>
                    </div>
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>

import {ElSelect,ElOption,ElButton,ElInput} from 'element-plus';
export default {
    name:"sign",
    data() {
        return {
            citys:[],
            data:{
                name:"",
                province:"",
                city: ""
            },
            config:{
                provinces:[],
                citys:[]
            }
        }
    },
    components:{
        ElSelect,
        ElOption,
        ElButton,
        ElInput,
    },
    created() {
        document.getElementsByTagName("title")[0].innerHTML = "签到"
    },
    mounted(){
        console.log(this.$route.query)
        this.initCitys()
    },
    methods: {
        
        async initCitys (){
            let citys = await this.$tools.getCityConfig()
            console.log(citys)
            this.citys = this.$tools.sortChinese(citys,"name")
            this.initProvince();
        },
        initProvince() {
            this.config.provinces = this.citys.map(x=>x.name);
            if(this.data.city) this.changeProvince()
        },
        changeProvince(value) {
            let index = this.config.provinces.indexOf(this.data.province);
            if(value) this.data.city = '';
            this.config.citys = this.$tools.sortChinese(this.citys[index].sub.map(x=>x.name))
        },
        verifyData(){
            if(!this.data.name){
                this.$tips.error({text:"请输入姓名"})
                return false;
            }
            if(!this.data.province){
                this.$tips.error({text:"请选择省份"})
                return false;
            }
            if(!this.data.city){
                this.$tips.error({text:"请选择城市"})
                return false;
            }
            return true;
        },
        doCommit(){
            if(this.loading || !this.verifyData()) return;
            this.loading = true;
            this.$axios.post(this.$urls.meet.sign,this.data).then(res=>{
                this.$tips.success({text:"签到成功"})
                this.$router.push({
                    path:`/meeting/${this.$route.query.meetingId}`,
                    query:{
                        ...this.$route.query
                    }
                })
            }).catch(err=>{
                setTimeout(()=>{ this.loading = false },1000)
            })
        }
    },
}
</script>
<style>
.sign_container{
    width: 100%;
    height: 100%;
    padding: 0 8.4vw;
    background: #fff;
}
.sign_header{
    width: 100%;
    height: 53.6vw;
    padding:17.3vw  0 13.3vw 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sign_header_img{
    height: 14.4vw;
    width: 14.4vw;
    padding: 3.6vw;
    background: url('../../../assets/images/large.png');
    background-size: 100%;
}
.sign_header_img img{
    width: 100%;
    height: 100%;
}
.sign_header_text{
    color: #0100c8;
    font-size: 4vw;
    font-weight: 400;
    margin-top: 4.4vw;
}
.sign_body{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.sign_information_input{
    height: 13.4vw;
    display: flex;
    align-items: center;
    padding-top: 3.8vw;
}
.sign_information_input_key{
    width: 10vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sign_information_input_value{
    flex: 1 0 0;
    height:100%;
    border-bottom:1px solid #ececec
}
.sign_button{
    margin-top: 25.33vw;
    display: flex;
    justify-content: center;
}
.sign_button .el-button{
    border: none;
    width: 60vw;
    height: 8.16vw;
    border-radius: 1vw;
    background: url('../../../assets/images/icon/button.png');
    background-size: 100%;
}
 .sign_button .el-button:hover{
    background: url('../../../assets/images/icon/button.png');
    background-size: 100%;
 }
 .button_tip_block{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
 }
 .button_img{
     height: 100%;
     width: 4.8vw;
     margin-right: 2vw;
 }
 .button_img img{
     height: 100%;
     width: 100%;
 }
 .button_text{
    width: 16.5vw;
    height: 100%;
    padding: 2vw 0;
    color:#fff;
    font-size: 4vw;
    font-weight: 400;
    display: flex;
    align-items: center;
 }
</style>
